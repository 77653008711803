import React, { useEffect, useRef, useState } from "react";
import clsx from 'clsx';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Section from "../components/section";
import SEO from "../components/seo";
import AILogo from "../assets/artificial-intelligence.svg"
import RocketLogo from "../assets/rocket.svg"
import CustomerSupportLogo from "../assets/customer-support.svg"
import SaveMoneyLogo from "../assets/save-money.svg"
import CTADemo from "../components/sections/cta-demo";

const FeaturesPage = () => {
    const [isVideoLoaded, setVideoLoaded] = useState(false);
    const videoRef = useRef();

    const onVideoLoad = () => {
        setVideoLoaded(true);
    };

    const onVideoLoadError = () => {
    }

    const onVideoPause = () => {
        try {
            videoRef.current.play();
        } catch (err) {
            console.error(err);
        }
    }


    useEffect(() => {
        if (isVideoLoaded) {
            try {
                videoRef.current.play();
            } catch (err) {
                console.error(err);
            }
        }
    }, [isVideoLoaded]);

    // const front = ({hover}) => ({
    //     position: "absolute",
    //     transform: hover? "perspective(600px) rotateY(-180deg)" : "perspective(600px) rotateY(0deg)",
    //     backfaceVisibility: "hidden",
    //     transition: "transform .5s linear 0s",
    //     visibility: "hidden"
    // });
    
    // const back = ({hover}) => ({
    //     position: "absolute",
    //     transform: hover? "perspective(600px) rotateY(0deg)" : "perspective(600px) rotateY(180deg)",
    //     background: "#262626",
    //     color: "#fff",
    //     padding: "5px",
    //     backfaceVisibility: "hidden",
    //     transition: "transform .5s linear 0s",
    // });

    // const [hover, setHover] = useState(false);

    return (
        <Layout>
            {/* <section
                id="hero"
                className="relative bg-slate-900/50 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']"
            >

                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <video
                        onLoadedData={onVideoLoad}
                        onPause={onVideoPause}
                        ref={videoRef}
                        preload="auto"
                        autoPlay
                        loop
                        muted
                        playsInline
                        tabIndex="-1"
                        className={clsx(
                            "object-cover w-full h-full max-h-full aspect-video object-center"
                        )}
                    >
                        <source src={FeaturesPageVideoWebM} type="video/webm" />
                        <source src={FeaturesPageVideoMP4} type="video/mp4" onError={onVideoLoadError} />
                    </video>
                </div>

                <div className="z-10 relative flex h-full items-center px-8 py-36 sm:py-48 md:py-64 xl:px-0 container max-w-7xl mx-auto">
                    <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Features
                            </h1>
                            <p className="text-lg md:text-xl font-normal">
                                Coeus combines a customizable, easy-to-use user interface with AI to provide the experience your customers demand.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}

            <section id="hero" className="relative bg-slate-900/70 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']">
                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <StaticImage alt="features" src="../assets/features-bg-2.jpeg" className="w-full h-full" imgClassName="object-cover w-full h-full" layout="fullWidth" />
                </div>

                <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-36 sm:py-48 md:py-60 xl:px-0 container max-w-7xl mx-auto">
                    <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Features
                            </h1>
                            <p className="text-lg md:text-xl font-normal">
                                Coeus combines a customizable, easy-to-use user interface with AI to provide the experience your customers demand.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Section className="">
                <div className="space-y-10 lg:mx-auto lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
                    <StaticImage
                        src="../assets/features-1.png"
                        className="shadow-md rounded-xl overflow-hidden"
                        imgClassName="rounded-xl"
                        quality={100}
                        placeholder="blurred"
                        loading="lazy"
                        layout="fullWidth"
                        alt=""
                    />
                    <div className="">
                        <h1 className="text-center text-3xl md:text-4xl font-bold leading-none">Key Capabilities</h1>
                        <div className="mt-4 grid grid-cols-1  md:grid-cols-2 gap-6">
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Provide a Better Customer Experience</h2>
                                <p className="text-base md:text-lg font-light leading-normal">24/7 access to a virtual assistant to answer most customer requests, complete routine tasks, and pass customer information to an agent. </p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Provide a Better Agent Experience</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Reduce call volumes while focusing your staff on more complex, higher-value transactions.</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Orchestrate the Use of Existing Systems</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Secure data and communications, providing integration with your backend systems to handle any given situation. </p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Natural Language Conversations</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Coeus will converse with your stakeholders in normal human dialog, like you would talk to another person.</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Learn Over Time</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Coeus learns from your existing systems and its own interactions via conversations. </p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Rapid Deployment</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Get Coeus AI assistant in as little as 6 weeks in the cloud or on premise.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <Section className="bg-gray-50">
                <div className="space-y-10 lg:mx-auto lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
                    <div className="">
                        <h1 className="text-center text-3xl md:text-4xl font-bold leading-none">Use Cases</h1>
                        <div className="mt-4 grid grid-cols-1  md:grid-cols-2 gap-6">
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Agent assist</h2>
                                <p className="text-base md:text-lg font-light leading-normal">We provide fast and consistent information back to the call center agent decreasing call times and saving money for your company.</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Customer assist</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Our solution understands questions in natural language - either spoken or typed, and returns the answer in clear, concise terms in the language in which it was asked.</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg md:text-xl font-semibold leading-tight">Employee assist</h2>
                                <p className="text-base md:text-lg font-light leading-normal">Employees want quick access to their information. Coeus can answer general questions without the need to talk to HR and other departments. Questions like paid holidays, number of vacation days, W2 access/delivery are easily answered by Coeus anytime 24/7/365.</p>
                            </div>
                        </div>
                    </div>

                    <StaticImage
                        src="../assets/call-center-person-smiling.png"
                        className="shadow-md rounded-xl overflow-hidden"
                        imgClassName="rounded-xl"
                        quality={100}
                        placeholder="blurred"
                        loading="lazy"
                        layout="fullWidth"
                        alt=""
                    />
                </div>
            </Section>

            <Section className="">
                <h1 className="text-3xl md:text-4xl font-bold text-center leading-none">Key Benefits</h1>
                <div className="mt-10 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-4 content-center w-full">
                    <div className="px-8 py-8 rounded-md border border-borderLight flex flex-col space-y-5">
                        <AILogo className="w-24 h-24 mx-auto text-gray-900" />
                        <h3 className="text-xl font-semibold leading-tight">Based on IBM’s AI and Red Hat’s OpenShift technologies</h3>
                    </div>
                    <div className="px-8 py-8 rounded-md border border-borderLight flex flex-col space-y-5">
                        <RocketLogo className="w-24 h-24 mx-auto text-gray-900" />
                        <h3 className="text-xl font-semibold leading-tight">Quickly deploy and scale a cost-effective solution</h3>
                    </div>
                    <div className="px-8 py-8 rounded-md border border-borderLight flex flex-col space-y-5">
                        <CustomerSupportLogo className="w-24 h-24 mx-auto text-gray-900" />
                        <h3 className="text-xl font-semibold leading-tight">Fast and effective customer experiences with consistent answers</h3>
                    </div>
                    <div className="px-8 py-8 rounded-md border border-borderLight flex flex-col space-y-5">
                        <SaveMoneyLogo className="w-24 h-24 mx-auto text-gray-900" />
                        <h3 className="text-xl font-semibold leading-tight">Lower call center costs by reducing call times and call volumes</h3>
                    </div>

                </div>
            </Section>

            <Section className="bg-gray-50">
                <h1 className="text-3xl md:text-4xl font-bold text-center leading-none">Integrations</h1>
                <div className="mt-10 gap-y-10 grid grid-cols-3 lg:grid-cols-5 content-center w-full">
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/AWS_EC2_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="AWS EC2 Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">AWS Elastic Compute Cloud</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/AWS_EKS_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="AWS EKS Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">AWS Elastic Kubernetes</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/AWS_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Red Hat logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">AWS</h1> 
                            <ul className="list-disc list-inside">
                                <li>Elastic Compute Cloud</li>
                                <li>Elastic Kubernetes</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Facebook_Name_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Facebook Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Facebook</h1>
                            <ul className="list-disc list-inside">
                                <li>WhatsApp</li>
                                <li>Messenger</li>
                                <li>ReactJS</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Google_Cloud_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Google Cloud Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Google Cloud</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Google_Kubernetes_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Google Kubernetes Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Google Kubernetes</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Google_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Red Hat logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Google</h1> 
                            <ul className="list-disc list-inside">
                                <li>Maps</li>
                                <li>Kubernetes Engine</li>
                                <li>Cloud</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Google_Maps_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Google Maps Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Google Maps</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/IBM_Cloud_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="IBM Cloud Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">IBM Cloud</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/IBM_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Red Hat logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">IBM</h1> 
                            <ul className="list-disc list-inside">
                                <li>Watson Assistant</li>
                                <li>Watson Discovery</li>
                                <li>Text To Speech / Speech To Text</li>
                                <li>Cloud Kubernetes Service</li>
                                <li>IBM HIPAA-Ready Cloud</li>
                                <li>IBM Virtual Private Cloud</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/IBM_Kubernetes_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="IBM Kubernetes Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">IBM Kubernetes</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/IBM_Watson_AssistantDiscovery_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="IBM Watson Assistant Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">IBM Wastson Assistant</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Keycloak_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Keycloak Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Keycloak</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/LDAP_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="LDAP Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Lightweight Directory Access Protocol</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_AKS_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft AKS Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft AKS</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_Azure_AD_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft Azure AD Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft Azure AD</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_Azure_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft Azure Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft Azure</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_Graph_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft Graph Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft Graph</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft</h1>
                            <ul className="list-disc list-inside">
                                <li>Azure AD</li>
                                <li>Outlook Calendar</li>
                                <li>Graph API</li>
                                <li>Azure Cloud</li>
                                <li>Managed Kubernetes Service</li>
                                <li>Teams</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_Outlook_Calendar_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft Outlook Calendar Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft Outlook Calendar</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Microsoft_Teams_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Microsoft Teams Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Microsoft Teams</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/MongoDB_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="MongoDB Atlas Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">MongoDB Atlas</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Pindrop_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Pindrop Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Pindrop</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Red_Hat_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Red Hat logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">RedHat</h1> 
                            <ul className="list-disc list-inside">
                                <li>OpenShift</li>
                                <li>ROSA</li>
                                <li>iDaaS</li>
                                <li>Directory Server</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Openshift_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="RedHat Openshift Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">RedHat Openshift</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Slack_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Slack Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Slack</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Soul_Machines_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Soul Machines Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Soul Machines</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/OpenAI_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Open AI Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Open AI</h1>
                            <ul className="list-disc list-inside">
                                <li>GPT-3</li>
                                <li>ChatGPT</li>
                            </ul>
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Twilio_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Twilio Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Twilio</h1> 
                        </div>
                    </div>
                    <div className='logoContainer flex justify-center'>
                        <div className="front" >
                            <StaticImage
                            src="../assets/partnership-logos/Yext_Logo.png"
                            placeholder="blurred"
                            loading="lazy"
                            height={90}
                            alt="Yext Logo"
                            />
                        </div>
                        <div className="back" >
                            <h1 className="text-center text-lg">Yext</h1> 
                        </div>
                    </div>
                </div>
            </Section>

            <CTADemo />
        </Layout>
    );
};

export default FeaturesPage;


export const Head = () => {
    return (
        <SEO
            title="Features"
            description="Coeus combines a customizable, easy-to-use user interface with AI to provide the experience your customers demand."
        >
        </SEO>
    );
}